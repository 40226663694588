<i18n>
ru:
  delivery: Курьером
  inHall: В зале
  orderType: Тип заказа
  selfService: Самовывоз
ua:
  delivery: Курь'єром
  inHall: В залі
  orderType: Тип замовлення
  selfService: Самовивіз
us:
  delivery: Courier delivery
  inHall: In restaurant hall
  orderType: Order type
  selfService: Self-service
</i18n>

<template>
  <ui-option-slider
    v-if="loaded"
    :is-item-disabled="isItemDisabled"
    :label="translate('orderType.orderType')"
    :options="switches"
    data-test-id="order-type-selector"
    v-model:selected="orderType"
  >
    <template #option="option: { value: number | undefined }">
      <div
        v-if="option.value !== undefined"
        :data-test-id="dataTestId(option.value)"
      >
        <div
          v-if="compact || showIconComputed"
          class="v-d-flex v-align-items-center"
        >
          <div :class="{ 'v-mr-xs': !compact }">
            <icon-old-order-type-courier
              v-if="option.value === OrderType.CourierDelivery"
              class="v-order-icon"
            />
            <template v-else-if="option.value === OrderType.NoShipment">
              <icon-old-order-type-self-service class="v-order-icon" />
            </template>
            <icon-old-order-type-in-hall
              v-else-if="option.value === OrderType.InHall"
              class="v-order-icon"
            />
          </div>

          <template v-if="!compact">
            <span
              v-if="option.value === OrderType.CourierDelivery"
              v-html="translate('orderType.delivery')"
            />
            <span
              v-else-if="option.value === OrderType.NoShipment"
              v-html="translate('orderType.selfService')"
            />
            <span
              v-else-if="option.value === OrderType.InHall"
              v-html="translate('orderType.inHall')"
            />
          </template>
        </div>
        <template v-else>
          <template v-if="option.value === OrderType.CourierDelivery">
            {{ translate('orderType.delivery') }}
          </template>
          <template v-else-if="option.value === OrderType.NoShipment">
            {{ translate('orderType.selfService') }}
          </template>
          <template v-else-if="option.value === OrderType.InHall">
            {{ translate('orderType.inHall') }}
          </template>
        </template>
      </div>
    </template>
  </ui-option-slider>
  <common-skeleton
    v-else
    :height="isInHeader ? 32 : 40"
  />
</template>

<script setup lang="ts">
import type { Terminal } from '~types/addressStore'

import { useWindowSize } from '@arora/common'

import { OrderType } from '~api/consts'

const { isInHeader, orderTypeShowIcons } = defineProps<{
  compact?: boolean
  orderTypeShowIcons?: boolean
  isInHeader?: boolean
}>()

const showIconComputed = computed<boolean>(() => {
  if (isInHeader) return orderTypeShowIcons
  return (
    appConfig.VueSettingsPreRun.OrderTypeShowIconsInCart &&
    !appConfig.VueSettingsPreRun.OrderTypeDisableIcons
  )
})

const loaded = computed(
  () =>
    addressStore.DeliveryAndPay?.data !== null &&
    addressStore.Terminals?.data !== null
)

const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
const addressStore = useAddressStore()
const clientStore = useClientStore()

function setFirstSwitchAvailable(): void {
  if (
    switches.value.length > 0 &&
    (!orderType.value || orderType.value === OrderType.Default)
  )
    clientStore.selectOrderType({
      orderType: switches.value[0]
    })
}

onMounted(async () => {
  if (import.meta.client) {
    await clientStore.initClientState()
    await addressStore.initDeliveryAndPay()
    await addressStore.initTerminals()

    //prevent clash with other requests
    setTimeout(() => {
      setFirstSwitchAvailable()
    }, 300)
  }
})

const orderType = computed<number | undefined>({
  get() {
    return clientStore.ClientState.data?.OrderType
  },
  set(value) {
    if (value !== undefined)
      clientStore.selectOrderType({
        orderType: value
      })
  }
})

const switches = computed<number[]>(() => {
  const result: number[] = []

  if (!addressStore.DeliveryAndPay.data) return result

  const enableSelfService = addressStore.DeliveryAndPay.data.EnableSelfService
  const enableDeliveryCourier =
    addressStore.DeliveryAndPay.data.EnableDeliveryCourier
  const enableInHall = addressStore.DeliveryAndPay.data.EnableInHall

  if (enableDeliveryCourier) {
    result.push(OrderType.CourierDelivery)
  }

  if (enableSelfService) {
    result.push(OrderType.NoShipment)
  }

  if (enableInHall) {
    result.push(OrderType.InHall)
  }

  return result
})

function isItemDisabled(index: number | undefined): boolean {
  if (index === OrderType.NoShipment) {
    return (
      addressStore.Terminals.data?.every(
        (terminal: Terminal) => !terminal.CanOrderSelfService
      ) ?? true
    )
  }

  if (index === OrderType.InHall) {
    return (
      addressStore.Terminals.data?.every(
        (terminal: Terminal) => terminal.InHallSetting.HallTablesNumber === 0
      ) ?? true
    )
  }

  return false
}

const orderTypeShowAsSelect = computed<boolean>(
  () => appConfig.VueSettingsPreRun.OrderTypeShowAsSelect
)
const asSelect = ref<boolean>(false)

const { isExtraSmall } = useWindowSize()

function dataTestId(orderType: number): string | null {
  switch (orderType) {
    case OrderType.CourierDelivery: {
      return 'courier-delivery'
    }
    case OrderType.NoShipment: {
      return 'no-shipment'
    }
    case OrderType.InHall: {
      return 'in-hall'
    }
  }

  return null
}

watch(
  () => isExtraSmall.value,
  (newValue) => {
    asSelect.value = orderTypeShowAsSelect.value || newValue
  }
)

watch(
  () => clientStore.ClientState.data?.Version ?? null,
  (newContent: number | null, oldContent: number | null) => {
    if (oldContent === null) return // after initialization oldContent is null
    if (newContent === null) return // in case of client state update error

    if (oldContent !== newContent) setFirstSwitchAvailable()
  }
)
</script>

<style lang="scss">
@use 'assets/variables';

.v-order-icon {
  width: 18px;
  height: 18px;
  fill: variables.$BodyTextColor;
}
</style>
